import React from 'react';
import { ToastContainer } from 'react-toastify';

import { ThemeProvider } from 'styled-components';

import GlobalStyle from '~/styles/global';
import theme from '~/styles/theme';

import Header from '~/components/Header';
import Acquisitions from '~/sections/Acquisitions';
import Focus from '~/sections/Focus';
import Footer from '~/sections/Footer';
import Form from '~/sections/Form';
import Presentation from '~/sections/Presentation';
import Real from '~/sections/Real';
import Structure from '~/sections/Structure';
import WhyAquisi from '~/sections/WhyAquisi';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastContainer />

      <main>
        <Header />
        <Presentation />
        <Acquisitions />
        <Real />
        <Focus />
        <WhyAquisi />
        <Structure />
        <Form />
        <Footer />
      </main>
    </ThemeProvider>
  );
}

export default App;
