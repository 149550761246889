import React from 'react';

import { Container } from './styles';

function Button({
  animation = false,
  temp = '1s',
  outline,
  light = false,
  children,
  ...rest
}) {
  return (
    <Container
      animation={animation}
      temp={temp}
      outline={outline}
      light={light}
      {...rest}
    >
      {children}
    </Container>
  );
}

export default Button;
