import React from 'react';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { TiSocialFacebook } from 'react-icons/ti';

import Button from '~/components/Button';
import { goTo } from '~/utils/goTo';

import { Container, Content } from './styles';

function Presentation() {
  return (
    <Container id="home">
      <Content>
        <div className="text">
          <span>É a sua aceleradora</span>
          <strong>Startup</strong>
          <p>Afinal, amamos transformar possibilidades em realidades.</p>
        </div>
        <Button outline animation temp="1.9s" onClick={() => goTo('#examples')}>
          Descubra
        </Button>
        <div className="social-networks">
          <TiSocialFacebook />
          <AiOutlineTwitter />
          <AiOutlineInstagram />
        </div>
      </Content>
    </Container>
  );
}

export default Presentation;
