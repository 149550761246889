import React from 'react';

import whyAquisi from '~/assets/images/why-aquisi.png';

import { Container, Content } from './styles';

function WhyAquisi() {
  return (
    <Container>
      <Content>
        <div className="content">
          <h1>Porque escolher a AQUISI?</h1>
          <p>
            A Aquisi seleciona cirurgicamente times, startups, produtos,
            serviços e processos inovadores no segmento de tecnologia, gerando
            resultados incríveis, tornando assim, empresas altamente
            consolidadas
          </p>
        </div>

        <img src={whyAquisi} alt="why aquisi" />
      </Content>
    </Container>
  );
}

export default WhyAquisi;
