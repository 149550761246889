import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';

import { useMedia } from 'use-media';

import logo from '~/assets/images/logo.png';

import Button from '~/components/Button';
import Menu from '~/components/Menu';
import { goTo } from '~/utils/goTo';

import { Container, Content, Image, NavBar } from './styles';

function Header() {
  const maxWidth800 = useMedia({ maxWidth: '800px' });

  const [open, setOpen] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(false);

  useEffect(() => {
    if (!maxWidth800) {
      setOpen(false);
    }
  }, [maxWidth800]);

  useEffect(() => {
    const menu = document.getElementById('header');

    const sticky = menu.offsetTop;

    function scroll() {
      const offsetY = window.pageYOffset;

      if (offsetY > sticky) {
        setHeaderFixed(true);
      } else {
        setHeaderFixed(false);
      }
    }

    window.onscroll = () => {
      scroll();
    };
  }, []);

  return (
    <>
      <Menu open={open} setOpen={setOpen} />
      <Container id="header" headerFixed={headerFixed}>
        <Content>
          <Image>
            <img src={logo} alt="Logo" />
          </Image>
          <NavBar>
            {maxWidth800 ? (
              <>
                <Button
                  onClick={() => goTo('#home')}
                  outline
                  style={{ marginRight: 20 }}
                >
                  Home
                </Button>
                <button type="button" onClick={() => setOpen(true)}>
                  {!open && <GiHamburgerMenu size={35} />}
                </button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => goTo('#home')}
                  outline
                  style={{ marginRight: 20 }}
                >
                  Home
                </Button>
                <Button onClick={() => goTo('#cases')}>Cases</Button>
                <Button onClick={() => goTo('#real')}>Sobre nós</Button>
                <Button
                  onClick={() => goTo('#form')}
                  style={{ paddingRight: 0 }}
                >
                  Cadastro
                </Button>
              </>
            )}
          </NavBar>
        </Content>
      </Container>
    </>
  );
}

export default Header;
