import React from 'react';

import { Container, Label, Content } from './styles';

function Input({ label, width = '100%', ...rest }) {
  return (
    <Container width={width}>
      <Label>{label}</Label>
      <Content {...rest} />
    </Container>
  );
}

export default Input;
