import styled from 'styled-components';

export const Container = styled.section`
  padding: 100px 30px;
`;

export const Content = styled.div`
  width: 100%;
  height: 250px;

  max-width: 1300px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .image {
    width: 40%;
    height: 100%;
    min-width: 380px;

    display: flex;
    justify-content: flex-end;

    img {
      height: 100%;
    }
  }

  .content {
    width: 60%;
    height: 80%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h1 {
      font-size: 60px;
      font-family: QanelasBold;
    }

    p {
      font-size: 20px;
    }
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    height: auto;

    .image {
      display: flex;
      justify-content: center;
      height: 250px;

      width: 100%;
      margin-top: 60px;
    }

    .content {
      width: 100%;

      h1 {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }

  @media (max-width: 430px) {
    .image {
      min-width: 0;

      img {
        width: 100%;
      }
    }

    .content {
      h1 {
        font-size: 50px;
      }
    }
  }
`;
