import styled, { css, keyframes } from 'styled-components';

const animation = keyframes`
  from {
    transform: translate(-2000px)
  }
  to {
    transform: translate(0)
  }
`;

export const Container = styled.button`
  border-color: ${(props) =>
    props.light
      ? props.theme.palette.primary.color
      : props.theme.palette.secondary.color};
  border-style: ${(props) => props.outline && 'solid'};
  box-shadow: inset;
  border-width: 2px;
  border-radius: 50px;
  padding: 10px 20px;
  font-weight: bold;
  font-family: ${(props) => (props.outline ? 'QanelasBold' : 'Qanelas')};
  font-size: 20px;
  transition: 0.8s opacity;
  color: ${(props) =>
    props.outline
      ? props.light
        ? props.theme.palette.secondary.color
        : props.theme.palette.primary.color
      : 'none'};
  background: ${(props) =>
    props.outline
      ? props.light
        ? props.theme.palette.primary.color
        : props.theme.palette.secondary.color
      : 'none'};

  ${(props) =>
    props.animation &&
    css`
      animation: ${animation} ${props.temp};
    `}

  &:hover {
    opacity: ${(props) => (props.outline ? '0.8' : '0.6')};
  }
`;
