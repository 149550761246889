import React from 'react';

import companyGroup from '~/assets/images/company-group.png';

import { Container, Content } from './styles';

function Real() {
  return (
    <Container id="real">
      <Content>
        <div className="content">
          <h1>Somos Reais</h1>
          <p>
            Somos uma equipe de profissionais prontos para te ajudar a evoluir.
            Aquisi é uma empresa inovadora, tendo como única missão ajudar
            empreendedores a alcançar a tão desejada independência financeira.
          </p>
        </div>
        <div className="image">
          <img src={companyGroup} alt="CompanyImage" />
        </div>
      </Content>
    </Container>
  );
}

export default Real;
