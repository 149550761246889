import styled from 'styled-components';

import backgroundForm from '~/assets/images/background-form.png';

import 'react-toastify/dist/ReactToastify.css';

export const Container = styled.section`
  background: url(${backgroundForm}) no-repeat;
  background-position: top;
  background-size: cover;

  height: 100vh;
  width: 100%;

  .overlay {
    background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    );

    width: 100%;
    height: 100%;
  }

  @media (max-width: 1200px) {
    background-position: right;
  }

  @media (max-width: 500px) {
    padding: 0;
  }

  @media (max-width: 600px) {
    height: auto;
    background: none;
  }

  @media (max-width: 600px) {
    .overlay {
      padding-bottom: 50px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  height: 100%;
  padding: 0 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 1200px) {
    align-items: center;
  }
`;

export const Text = styled.h1`
  margin-bottom: 80px;
  font-size: 50px;

  @media (max-width: 600px) {
    padding: 40px 30px 0 30px;
  }

  @media (max-width: 470px) {
    margin-bottom: 30px;
    font-size: 40px;
  }
`;

export const ProjectForm = styled.div`
  width: 100%;
  max-width: 700px;

  background: #fff;

  padding-top: 50px;
  border-radius: 5px;

  box-shadow: 5px 5px 2px #000;

  .header {
    margin-bottom: 30px;
    padding: 0 50px;

    h1 {
      font-size: 25px;
    }
  }

  form {
    padding: 0 50px;

    .inputs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .label {
      width: 100%;
      margin-bottom: 5px;
      font-weight: bold;
      color: #777;
    }

    .details {
      display: flex;
      flex-direction: column;

      textarea {
        width: 100%;
        border: 2px solid #ccc;
        padding: 15px;
        border-radius: 10px;
        font-weight: bold;

        &:focus {
          border-color: #000;
          font-size: 18px;
          box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .attachments {
      display: flex;
      flex-direction: column;

      span {
        width: 100%;
        margin-bottom: 5px;
        font-weight: bold;
        color: #777;
      }

      #input-file {
        display: none;
      }

      #label {
        width: 100%;
        border: 2px solid #ccc;
        padding: 15px;
        border-radius: 10px;
        font-weight: bold;
        transition: 0.4s opacity;

        &:focus {
          border-color: #000;
          font-size: 18px;
          box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
        }

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

      .images {
        display: flex;
        align-items: center;
        margin-top: 20px;

        img {
          width: 150px;
          height: 150px;
          margin-right: 10px;
        }

        .max {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;

          width: 110px;
          height: 110px;
          border-radius: 10px;
          border: 2px solid #999;

          color: #999;
          font-size: 27px;
        }
      }
    }
  }

  .navbar {
    width: 100%;
    height: 80px;

    padding-right: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 800px) {
    form {
      .attachments {
        .images {
          flex-direction: column;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .header {
      padding: 0 30px;

      h1 {
        font-size: 30px;
      }
    }

    form {
      padding: 0 20px;
    }
  }

  @media (max-width: 585px) {
    .navbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px 20px 20px;
    }
  }

  @media (max-width: 470px) {
    box-shadow: none;

    .header {
      h1 {
        font-size: 25px;
      }
    }

    form {
      .inputs {
        flex-direction: column;
      }
    }
  }
`;
