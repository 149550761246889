import axios from 'axios';

export const getAddress = async (cep) => {
  const response = await axios({
    method: 'get',
    url: `https://viacep.com.br/ws/${cep}/json`,
  });

  return response.data;
};
