import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { validateBr } from 'js-brasil';
import { useMedia } from 'use-media';

import Button from '~/components/Button';
import Input from '~/components/Input';
import { getAddress } from '~/services/viaCep';
import { unmask, maskPostalCode, maskPhoneNumber } from '~/utils/masks';
import { extractDigits } from '~/utils/string';

import { Container, Content, Text, ProjectForm } from './styles';

function Form() {
  const maxWidth470 = useMedia({ maxWidth: '470px' });

  const inputWidth = maxWidth470 ? '100%' : '49%';
  const cepWidth = maxWidth470 ? '100%' : '29%';
  const addressWidth = maxWidth470 ? '100%' : '69%';

  const [step, setStep] = useState(1);
  const [attachmentsExcess, setAttachmentsExcess] = useState();
  const [stepTitle, setStepTitle] = useState('');

  // step 1
  const [name, setName] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  // step 2
  const [companyName, setCompanyName] = useState('');
  const [description, setDescription] = useState('');

  // step 3
  const [attachments, setAttachments] = useState([]);

  function handleNextStep() {
    if (step === 1) {
      if (
        !name ||
        !postalCode ||
        !address ||
        !city ||
        !state ||
        !email ||
        !phone
      ) {
        toast.warn('Preencha todos os campos para prosseguir');
        return;
      }
      if (email && !validateBr.email(email)) {
        toast.warn('Email inválido');
        return;
      }
    }

    if (step === 2) {
      if (!companyName || !description) {
        toast.warn('Preencha todos os campos para prosseguir');
        return;
      }
    }

    if (step <= 2) {
      setStep(step + 1);
    }
  }

  function handleBackStep() {
    if (step !== 1) {
      setStep(step - 1);
    }
  }

  function handleUploadFiles(e) {
    const { files } = e.target;

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (item) => {
      const data = item.target.result;
      setAttachments([...attachments, data]);
    };
  }

  async function handleChangeCep(e) {
    const newCep = e.target.value;

    setPostalCode(newCep);

    const cepNumber = extractDigits(newCep);
    if (cepNumber.length === 8) {
      const cepData = await getAddress(cepNumber);

      if (cepData) {
        if (cepData.uf) {
          setState(cepData.uf);
        }
        if (cepData.localidade) {
          setCity(cepData.localidade);
        }
      }
    }
  }

  useEffect(() => {
    if (attachments.length >= 4) {
      const value = attachments.length - 3;
      setAttachmentsExcess(value);
    }
  }, [attachments.length]);

  useEffect(() => {
    switch (step) {
      case 1:
        setStepTitle('Seus dados');
        break;

      case 2:
        setStepTitle('Sobre sua empresa');
        break;

      case 3:
        setStepTitle('Anexos');
        break;

      default:
        break;
    }
  }, [step]);

  return (
    <Container id="form">
      <div className="overlay">
        <Content>
          <Text>CADASTRE SEU PROJETO</Text>
          <ProjectForm>
            <div className="header">
              <h1>{stepTitle}</h1>
            </div>
            {step === 1 ? (
              <form>
                <div className="content">
                  <Input
                    label="Nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div className="inputs">
                    <Input
                      width={cepWidth}
                      label="Cep"
                      value={maskPostalCode(postalCode)}
                      onChange={handleChangeCep}
                      maxLength={9}
                    />
                    <Input
                      width={addressWidth}
                      label="Endereco"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="inputs">
                    <Input
                      width={inputWidth}
                      label="Cidade"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <Input
                      width={inputWidth}
                      label="Estado"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  <div className="inputs">
                    <Input
                      width={inputWidth}
                      label="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                      width={inputWidth}
                      label="Telefone"
                      value={maskPhoneNumber(phone)}
                      onChange={(e) => setPhone(unmask(e.target.value))}
                      maxLength={16}
                    />
                  </div>
                </div>
              </form>
            ) : step === 2 ? (
              <form>
                <Input
                  label="Nome da empresa"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <div className="details">
                  <span className="label">
                    Escreva um pouco sobre seu projeto
                  </span>
                  <textarea
                    cols="30"
                    rows="10"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </form>
            ) : (
              <form>
                <div className="attachments">
                  <span>Arquivos (opcional)</span>
                  <input
                    id="input-file"
                    type="file"
                    onChange={handleUploadFiles}
                  />
                  <label htmlFor="input-file" id="label">
                    Escolha um arquivo
                  </label>
                  <div className="images">
                    {attachments.length >= 1 && (
                      <img src={attachments[0]} alt="" />
                    )}
                    {attachments.length >= 2 && (
                      <img src={attachments[1]} alt="" />
                    )}
                    {attachments.length >= 3 && (
                      <img src={attachments[2]} alt="" />
                    )}
                    {attachments.length >= 4 && (
                      <div className="max">+ {attachmentsExcess}</div>
                    )}
                  </div>
                </div>
              </form>
            )}
            <div className="navbar">
              {step === 1 && (
                <Button outline onClick={handleNextStep}>
                  Continuar
                </Button>
              )}
              {step === 2 && (
                <>
                  <Button onClick={handleBackStep}>Voltar</Button>
                  <Button outline onClick={handleNextStep}>
                    Continuar
                  </Button>
                </>
              )}
              {step === 3 && (
                <>
                  <Button onClick={handleBackStep}>Voltar</Button>
                  <Button outline onClick={handleNextStep}>
                    Finalizar
                  </Button>
                </>
              )}
            </div>
          </ProjectForm>
        </Content>
      </div>
    </Container>
  );
}

export default Form;
