import React from 'react';

import light from '~/assets/images/light.png';

import { Container, Content } from './styles';

function Focus() {
  return (
    <Container>
      <Content>
        <img src={light} alt="light" />

        <div className="content">
          <h1>Onde atuamos...</h1>
          <span>O processo da Aquisi consiste nas etapas de</span>
          <strong>Aceleração, investimento e capitalização</strong>
        </div>
      </Content>
    </Container>
  );
}

export default Focus;
