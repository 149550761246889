import React, { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { useMedia } from 'use-media';

import pagaRapidoComputer from '~/assets/images/paga-rapido-computer.png';
import pagaRapidoLogo from '~/assets/images/paga-rapido-logo.png';
import posFacilComputer from '~/assets/images/pos-facil-computer.png';
import posFacilLogo from '~/assets/images/pos-facil-logo.png';
import praNotasComputer from '~/assets/images/pra-notas-computer.png';
import praNotasLogo from '~/assets/images/pra-notas-logo.png';
import praVendasComputer from '~/assets/images/pra-vendas-computer.png';
import praVendasLogo from '~/assets/images/pra-vendas-logo.png';
import rsSolutionsComputer from '~/assets/images/rs-solutions-computer.png';
import rsSolutionsLogo from '~/assets/images/rs-solutions-logo.png';
import suaMaquinaComputer from '~/assets/images/sua-maquina-computer.png';
import suaMaquinaLogo from '~/assets/images/sua-maquina-logo.png';

import { Container, Content } from './styles';

function Acquisitions() {
  const maxWidth780 = useMedia({ maxWidth: '780px' });

  const [siteUrl, setSiteUrl] = useState('https://posfacil.app/');

  const [carouselProduct, setCarouselProduct] = useState('pos-facil');
  const [productText, setProductText] = useState({
    title: '',
    description: '',
  });

  const [carouselLogo, setCarouselLogo] = useState(posFacilLogo);
  const [carouselComputer, setCarouselComputer] = useState(posFacilLogo);

  function handleNext() {
    if (carouselProduct === 'pos-facil') {
      setCarouselProduct('pra-notas');
    }

    if (carouselProduct === 'pra-notas') {
      setCarouselProduct('rs-solutions');
    }

    if (carouselProduct === 'rs-solutions') {
      setCarouselProduct('sua-maquina');
    }

    if (carouselProduct === 'sua-maquina') {
      setCarouselProduct('pra-vendas');
    }

    if (carouselProduct === 'pra-vendas') {
      setCarouselProduct('paga-rapido');
    }

    if (carouselProduct === 'paga-rapido') {
      setCarouselProduct('pos-facil');
    }
  }

  function handleBack() {
    if (carouselProduct === 'pos-facil') {
      setCarouselProduct('paga-rapido');
    }

    if (carouselProduct === 'pra-notas') {
      setCarouselProduct('pos-facil');
    }

    if (carouselProduct === 'rs-solutions') {
      setCarouselProduct('pra-notas');
    }

    if (carouselProduct === 'sua-maquina') {
      setCarouselProduct('rs-solutions');
    }

    if (carouselProduct === 'pra-vendas') {
      setCarouselProduct('sua-maquina');
    }

    if (carouselProduct === 'paga-rapido') {
      setCarouselProduct('pra-vendas');
    }
  }

  useEffect(() => {
    switch (carouselProduct) {
      case 'pos-facil':
        setCarouselLogo(posFacilLogo);
        setCarouselComputer(posFacilComputer);
        break;

      case 'paga-rapido':
        setCarouselLogo(pagaRapidoLogo);
        setCarouselComputer(pagaRapidoComputer);
        break;

      case 'pra-vendas':
        setCarouselLogo(praVendasLogo);
        setCarouselComputer(praVendasComputer);
        break;

      case 'pra-notas':
        setCarouselLogo(praNotasLogo);
        setCarouselComputer(praNotasComputer);
        break;

      case 'rs-solutions':
        setCarouselLogo(rsSolutionsLogo);
        setCarouselComputer(rsSolutionsComputer);
        break;

      case 'sua-maquina':
        setCarouselLogo(suaMaquinaLogo);
        setCarouselComputer(suaMaquinaComputer);
        break;

      default:
        break;
    }
  }, [carouselProduct]);

  useEffect(() => {
    switch (carouselProduct) {
      case 'pos-facil':
        setProductText({
          title: 'Aplicativo de vendas integrada com as melhores adquirentes.',
          description:
            'O POS FACIL é um Ponto de venda móvel e de alta performance. Veio para revolucionar a forma de vender. Tenha economia comaquisição de equipamentos, com o POS FACIL você vende, recebe, controla o seu caixa e ainda emite o cupom fiscal, tudo em um único aparelho.',
        });
        break;

      case 'paga-rapido':
        setProductText({
          title: 'Meio de Pagamento Seguro para vendas online.',
          description:
            'Faca suas vendas de maneira simplificada enviando link de pagamentos pelo Whatsapp , instagram , facebook e ainda conte com nossa solução de qrcode para lives podendo ser pago em cartão e boleto e pix. Moderno, simples e prático. Tudo para deixar o controle nas suas mãos.',
        });
        break;

      case 'pra-vendas':
        setProductText({
          title: 'Plataforma Ecommerce',
          description:
            'Comece a vender seus produtos de forma rapida e segura usando o PRA VENDAS. Nele, você faz sua conta, cadastra seus produtos e ja sai vendendo',
        });
        break;

      case 'pra-notas':
        setProductText({
          title: 'Gatway Fiscal',
          description:
            'Deixe a responsabilidade tributaria com a gente , melhor plataforma de emissão de notas fiscais. Integre seu aplicativo ou solução de erp para emitir as notas fiscais com nossa plataforma. Totalmente online e com api para sua integração.',
        });
        break;

      case 'rs-solutions':
        setProductText({
          title: 'Softhouse',
          description:
            'Fundada em 1995, a RS Solutions é uma empresa de Tecnologia da Informação especializada na criação de software de gerenciamento, possuindo vários modelos de negócios e criando as melhores soluções para cada tipo de situação.',
        });
        break;

      case 'sua-maquina':
        setProductText({
          title: 'Fintech White Label',
          description:
            'Criada em 2019 a sua marca traz para o mercado nacional um novo jeito de fazer negócio, disponibilizando uma integração white label, sendo possível qualquer pessoa ter sua própria empresa para soluções de pagamentos.',
        });
        break;

      default:
        break;
    }
  }, [carouselProduct]);

  useEffect(() => {
    switch (carouselProduct) {
      case 'pos-facil':
        setSiteUrl('https://posfacil.app/');
        break;

      case 'paga-rapido':
        setSiteUrl('https://pagarapido.com.br/');
        break;

      case 'pra-vendas':
        setSiteUrl('https://pravendas.com.br/');
        break;

      case 'pra-notas':
        setSiteUrl('https://pranotas.com.br/');
        break;

      case 'rs-solutions':
        setSiteUrl('rssolutions');
        break;

      case 'sua-maquina':
        setSiteUrl('https://sua-maquina-site.herokuapp.com/');
        break;

      default:
        break;
    }
  }, [carouselProduct]);

  return (
    <Container id="cases">
      <Content carouselProduct={carouselProduct}>
        <div className="header">
          {maxWidth780 ? (
            <>
              <strong>
                Conheça algumas das nossas aquisições Aceleradas com sucesso
              </strong>
            </>
          ) : (
            <>
              <h1>Conheça algumas das nossas aquisições</h1>
              <strong>Aceleradas com sucesso</strong>
            </>
          )}
        </div>

        <div className="content">
          <div className="carousel-container">
            <img src={carouselLogo} alt="paga rapido" />

            <div className="carousel">
              <button type="button" onClick={() => handleBack()}>
                <IoIosArrowBack />
              </button>
              <img src={carouselComputer} alt="paga rapido" />
              <button type="button" onClick={() => handleNext()}>
                <IoIosArrowForward />
              </button>
            </div>
            <a href={siteUrl}>CONHEÇA</a>
          </div>

          <div className="description">
            <h1>{productText.title}</h1>
            <span>{productText.description}</span>
          </div>
        </div>
      </Content>
    </Container>
  );
}

export default Acquisitions;
