import styled from 'styled-components';

export const Container = styled.section`
  background: ${({ theme }) => theme.palette.secondary.color};

  display: flex;
  justify-content: center;

  padding: 80px 30px;

  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1300px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h1 {
      color: ${({ theme }) => theme.palette.secondary.contrastColor};
      font-family: QanelasBlack;
      font-size: 32px;
      text-align: end;
    }

    strong {
      color: ${({ theme }) => theme.palette.secondary.contrastColor};
      font-family: QanelasBlack;
      font-size: 53px;
      text-align: end;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: -30px;

    .carousel-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 400px;

      > img {
        height: 75px;
      }

      a {
        background: ${({ theme }) => theme.palette.primary.color};
        color: ${({ theme }) => theme.palette.primary.contrastColor};
        font-family: QanelasBold;
        padding: 10px 20px;
        font-size: 20px;
        border-radius: 50px;
        transition: opacity 0.6s;

        &:hover {
          opacity: 0.7;
        }
      }

      .carousel {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 600px;
          height: 235px;
          margin-bottom: 0;
          padding: 0 10px;
        }

        button {
          transition: opacity 0.6s;

          &:hover {
            opacity: 0.6;
          }

          svg {
            color: ${({ theme }) => theme.palette.primary.color};
            font-size: 50px;
          }
        }
      }
    }

    .description {
      width: 100%;
      max-width: 400px;

      h1 {
        color: ${({ theme }) => theme.palette.secondary.contrastColor};
        font-size: 22px;
        margin-bottom: 20px;
      }

      span {
        color: ${({ theme }) => theme.palette.secondary.contrastColor};
        font-size: 20px;
      }
    }
  }

  @media (max-width: 1250px) {
    .header {
      align-items: center;
    }

    .content {
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
    }

    .description {
      margin-top: 50px;

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  @media (max-width: 780px) {
    .header {
      strong {
        text-align: center;
        font-size: 40px;
      }
    }

    .content {
      .carousel-container {
        width: 100%;

        .carousel {
          width: 100%;

          justify-content: center;

          img {
            width: 85%;
            height: auto;
            padding: 0;
          }
        }
      }
    }
  }

  @media (max-width: 580px) {
    .header {
      strong {
        text-align: center;
        font-size: 30px;
      }
    }
  }
`;
