import React from 'react';

import { useMedia } from 'use-media';

import structure1 from '~/assets/images/structure1.png';
import structure2 from '~/assets/images/structure2.png';
import structure3 from '~/assets/images/structure3.png';

import { Container, Content } from './styles';

function Structure() {
  const maxWidth1050 = useMedia({ maxWidth: '1050px' });

  return (
    <Container>
      <Content>
        <div className="text">
          {maxWidth1050 ? (
            <span>Conheça a nossa estrutura</span>
          ) : (
            <>
              <span>Conheça</span>
              <strong>
                nossa
                <br />
                estrutura
              </strong>
            </>
          )}
        </div>
        <div className="content">
          <div className="card">
            <img src={structure1} alt="structure" />
          </div>
          <div className="card">
            <img src={structure2} alt="structure" />
          </div>
          <div className="card">
            <img src={structure3} alt="structure" />
          </div>
        </div>
      </Content>
    </Container>
  );
}

export default Structure;
