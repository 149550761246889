import styled, { keyframes } from 'styled-components';

import backgroundDesktop from '~/assets/images/background-presentation-desktop.png';
import backgroundMobile from '~/assets/images/background-presentation-mobile.png';

const animation = keyframes`
  from {
    transform: translate(-2000px)
  }

  to {
    transform: translate(0)
  }
`;

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  background: url(${backgroundDesktop}) no-repeat;
  background-position: top;
  background-size: contain;

  height: 100vh;
  padding: 0 30px;

  position: relative;

  @media (max-width: 1200px) {
    background: url(${backgroundMobile});
    background-position: top;
    background-size: contain;
    align-items: flex-end;
  }

  @media (max-width: 800px) {
    background: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  height: 60%;
  width: 100%;
  max-width: 1300px;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    span {
      font-size: 54px;
      font-family: QanelasBold;

      animation: ${animation} 1s;
    }

    strong {
      font-size: 100px;
      font-family: QanelasBold;

      margin-bottom: 20px;
      animation: ${animation} 1.3s;
    }

    p {
      font-size: 18px;
      font-weight: bold;

      animation: ${animation} 1.6s;
    }
  }

  .social-networks {
    svg {
      width: 45px;
      height: 45px;
      margin-right: 30px;

      animation: ${animation} 2.2s;

      &:last-child {
        margin: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    height: 85%;
  }

  @media (max-width: 400px) {
    .text {
      span {
        font-size: 44px;
      }

      strong {
        font-size: 83px;
      }
    }
  }
`;
