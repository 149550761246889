import styled from 'styled-components';

export const Container = styled.section`
  background: ${({ theme }) => theme.palette.secondary.color};

  display: flex;
  justify-content: center;
  padding: 100px 30px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img {
    width: 100%;
    max-width: 220px;
  }

  .content {
    color: ${({ theme }) => theme.palette.secondary.contrastColor};

    display: flex;
    flex-direction: column;
    text-align: end;

    h1 {
      font-size: 50px;
      font-family: QanelasBold;
    }

    span {
      font-size: 30px;
      line-height: 40px;
    }

    strong {
      font-size: 30px;
      font-family: QanelasBold;
    }
  }

  @media (max-width: 1050px) {
    flex-direction: column;

    img {
      margin-bottom: 60px;
    }

    .content {
      text-align: center;
    }
  }

  @media (max-width: 720px) {
    .content {
      h1 {
        font-size: 45px;
      }

      span {
        font-size: 25px;
      }

      strong {
        font-size: 25px;
      }
    }
  }
`;
