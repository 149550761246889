import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.palette.secondary.color};
  padding: 0 30px;

  @media (max-width: 420px) {
    padding: 0;
  }
`;

export const Content = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;

  .text {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.palette.secondary.contrastColor};

    margin-right: 50px;
    white-space: nowrap;

    span {
      font-weight: bold;
      font-size: 25px;
      text-align: end;
    }

    strong {
      font-size: 55px;
      text-align: end;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 70px 0;
    overflow: auto;

    ::-webkit-scrollbar {
      height: 8px;
      background: #fff;
    }

    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.palette.secondary.color};
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.primary.color};
      border-radius: 5px;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;

      min-width: 320px;
      height: 270px;

      margin-right: 20px;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;

        border-radius: 10px;
      }
    }
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    width: 100%;

    .text {
      margin-right: 0;
      margin-top: 40px;

      span {
        font-size: 30px;
      }
    }

    .content {
      width: 100%;
    }
  }

  @media (max-width: 420px) {
    .text {
      span {
        font-size: 23px;
      }
    }
  }
`;
