import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.palette.secondary.color};
  padding: 0 30px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;

  .navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    width: 100%;

    .first {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        color: ${({ theme }) => theme.palette.secondary.contrastColor};
        font-size: 22px;
        margin-right: 50px;
        transition: opacity 0.5s;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .last {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      img {
        height: 60px;
        margin-bottom: 5px;
      }

      span {
        color: ${({ theme }) => theme.palette.secondary.contrastColor};
        font-size: 12.6px;
      }
    }
  }

  @media (max-width: 1050px) {
    .navbar {
      flex-direction: column;

      .first {
        flex-direction: column;

        button {
          margin-bottom: 30px;
          margin-right: 0;
        }
      }
    }
  }
`;
