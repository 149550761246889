import styled from 'styled-components';

export const Container = styled.div`
  width: ${(props) => props.width};

  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const Label = styled.label`
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  color: #777;
`;

export const Content = styled.input`
  width: 100%;
  border: 2px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  font-weight: bold;

  &:focus {
    border-color: #000;
    font-size: 18px;
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
