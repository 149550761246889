import styled from 'styled-components';

export const Container = styled.section`
  background: ${({ theme }) => theme.palette.primary.color};

  display: flex;
  justify-content: center;
  padding: 100px 30px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .content {
    padding-right: 50px;

    h1 {
      font-family: QanelasBold;
      font-size: 40px;
      margin-bottom: 20px;
    }

    p {
      font-size: 20px;
      line-height: 28px;
    }
  }

  img {
    width: 100%;
    max-width: 500px;
  }

  @media (max-width: 1050px) {
    flex-direction: column;

    .content {
      margin-bottom: 60px;
      padding-right: 0;

      h1 {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }
`;
