const theme = {
  palette: {
    primary: {
      color: '#f8f8f8',
      contrastColor: '#000',
    },
    secondary: {
      color: '#000',
      contrastColor: '#f8f8f8',
    },
  },
};

export default theme;
