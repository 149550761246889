import { createGlobalStyle } from 'styled-components';

import QanelasBlack from '~/assets/fonts/qanelas-black.otf';
import QanelasBold from '~/assets/fonts/qanelas-bold.otf';
import Qanelas from '~/assets/fonts/qanelas.otf';

export default createGlobalStyle`
   @font-face {
    font-family: 'QanelasBlack';
    src: local('QanelasBlack'), url(${QanelasBlack});
  }

  @font-face {
    font-family: 'Qanelas';
    src: local('Qanelas'), url(${Qanelas});
  }

  @font-face {
    font-family: 'QanelasBold';
    src: local('QanelasBold'), url(${QanelasBold});
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Qanelas', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;

    ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.palette.primary.color};
    }

    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.palette.primary.color};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.palette.secondary.color};
      border-radius: 5px;
    }
  }

  body {
    background: ${({ theme }) => theme.palette.primary.color};
    color: ${({ theme }) => theme.palette.primary.contrastColor};
    -webkit-font-smoothing: antialiased;
  }

  html, body, #root {
    width: 100%;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: bold;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
  }

  input {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }

  a {
    text-decoration: none;
  }
`;
