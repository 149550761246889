import styled, { css } from 'styled-components';

export const Container = styled.header`
  position: absolute;
  width: 100%;
  padding: 20px;
  transition: all 0.6s;

  z-index: 100;

  ${(props) =>
    props.headerFixed &&
    css`
      position: fixed;
      background-color: #fff;
      box-shadow: -3px -1px 7px #60606096;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: 1300px;
`;

export const Image = styled.div`
  img {
    height: 50px;
  }
`;

export const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
