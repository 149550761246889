import React from 'react';

import whiteLogo from '~/assets/images/white-logo.png';

import { goTo } from '~/utils/goTo';

import { Container, Content } from './styles';

function Footer() {
  return (
    <Container>
      <Content>
        <div className="navbar">
          <div className="first">
            <button type="button" onClick={() => goTo('#examples')}>
              Exemplos
            </button>
            <button type="button" onClick={() => goTo('#real')}>
              Sobre nós
            </button>
            <button type="button" onClick={() => goTo('#form')}>
              Cadastro
            </button>
          </div>
          <div className="last">
            <img src={whiteLogo} alt="logo" />
            <span>CNPJ: 04.2873.582/0001/11</span>
          </div>
        </div>
      </Content>
    </Container>
  );
}

export default Footer;
